<template>
    <div class="radio_box">
        <div class="radio_title">请选择支付方式：</div>
        <van-radio-group v-model="radio">
            <!-- <van-radio icon-size="16px" checked-color="#E3C084" name="1" @click="radioBtn(2)">
                <div class="wxPay">
                    <img src="../../assets/Slice35.png"/>
                    微信支付
                </div>
            </van-radio> -->
            <van-radio style="margin-top: 20px" icon-size="16px" checked-color="#E3C084" @click="radioBtn(1)" name="2">
                <div class="pricePay">
                    <img src="../../assets/Slice52.png"/>
                    凭证上传<span>（*可使用购购指定板块订单截图上传作凭证）</span>
                </div>
            </van-radio>
        </van-radio-group>
    </div>
</template>
<script>
export default {
    data() {
        return{
            radio:"2"
        }
    },
    methods: {
        radioBtn(index){
            this.$emit("radioBtn",index)
        }
    }
};
</script>
<style>
.radio_title{
    font-size: 30px;
    color:#fff;
    margin-bottom: 30px
}
.radio_box{
    margin: 30px 40px
}
.pricePay span{
    font-size: 18px;
    color:red
}
</style>