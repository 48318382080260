<template>
    <div class="online_box">
        <div class="online_title">收款账户信息</div>
        <div>
            <div class="address_title">收款账户信息</div>
            <div class="payInfo">
                <div>收款方：湖北利道科技投资有限公司工会委员会</div>
                <div>收款户名：湖北银行武汉江岸支行</div>
                <div>收款账户：10050220000000478</div>
            </div>
            
        </div>

        
        <div class="icon_box">
            <div class="online_title">请上传凭证</div>
            <div class="address_title" style="margin-bottom: 20px">上传凭证</div>
            <uploadImg :isNumber="isNumber" :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
        </div>
    </div>
</template>
<script>
import uploadImg from "../../components/uploadImg";
import * as apiCommon from "@/api/common";
import {getConfig_api} from "@/api/deal"
export default {
  data() {
    return {
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }"
      },
      img:'',
      configData:null,isNumber:true
    };
  },
  components: {
    uploadImg
  },
  created() {
    this.getToken();
    this.getConfig()
  },
  methods: {
     getConfig() {
      let data = {
        config: [
          "shares_sales_payee",
          "shares_sales_payee_account",
          "shares_sales_payee_address"
        ]
      };
      getConfig_api(data).then(res => {
        console.log(res.data);
        this.configData = res.data;
        this.$forceUpdate();
      });
    },
    uploadImg(data) {
      this.img = data;
      this.$emit("uploadImg",this.img);
      console.log(this.img, "2");
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.uploadData.imgToken = res.data;
    },
  }
};
</script>
<style>
.payInfo {
  width: 640px;
  height: 276px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.5);
  padding: 50px 0 0 70px;
  font-size: 30px;
  color: #e3c084;
  box-sizing: border-box;
  margin-top: 30px;
}
.payInfo div {
  margin-bottom: 30px;
}
.online_box {
  margin: 50px 55px;
}
.payIcon {
}
.payIcon img {
  width: 200px;
  height: 200px;
}
.icon_box {
  margin: 50px 0;
}
.online_title{
  font-size: 30px;
  color:rgba(46, 46, 48, 1)
}
</style>

